import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import { Link } from "react-scroll";
import Whatsapp from "@iconscout/react-unicons/icons/uil-whatsapp";

const Footer = () => {
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <div className="f-icons">
        <Link to="contact" smooth={true} spy={true}>
        <a href="https://web.whatsapp.com/send?phone=+9745539272">
            <Whatsapp color="white" size={"3rem"} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61560665318752&mibextid=ZbWKwL">
            <Facebook color="white" size={"3rem"} />
          </a>
          <a href="https://www.instagram.com/___chocoland?igsh=OHBqangycGVrbm0y">
            <Insta color="white" size={"3rem"} />
          </a>
        </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
