import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import cake from "../../img/cake.jpeg";
import cake1 from "../../img/cake1.jpeg";
import cake2 from "../../img/cake2.jpeg";
import cake3 from "../../img/cake3.jpeg";
import cake4 from "../../img/cake4.jpeg";
import cake5 from "../../img/cake5.jpeg";
import cake6 from "../../img/cake6.jpeg";
import cake7 from "../../img/cake7.jpeg";
import cake8 from "../../img/cake8.jpeg";
import cake9 from "../../img/cake9.jpeg";




const Testimonial = () => {
  const clients = [
    {
      img: cake,
      review:
        "Will be ordering from here again! Ordered a pull apart cupcake cake with 3 different flavors and requested  for my babies First Birthday. It looked SO good!! Not only did it look good, but it tasted great! Everyone raved about how good it looked and tasted."
    },
    {
      img: cake2,
      review:
        "Guests at my wedding said it was the best cake they have ever eaten. Not only was it yummy, but it was so pretty. She exceeded my expectations.",
    },
    {
      img: cake9,
      review:
        "They came through with a special request birthday cake for my daughter that was absolutely PERFECT!!! Sprinkle filled, ocean themed, beautifully decorated with great fondant work!!",
    },
    {
      img: cake1,
      review:
        "The cake was absolutely perfect and was super moist but the buttercream stole the show! My wife and I have never had any other come close to the flavor in the frosting.",
    },

    {
      img: cake4,
      review:
        "Fabulous!! The almond cake I ordered for  Birthday of my child was amazing!. Will order again for sure, we have found our specialty cake person. Everyone commented on the cake!!"
    },
    {
      img: cake5,
      review:
        "This vegan chocolate cupcake is large and dense, with a thick, fudgy consistency and rich chocolate icing in the middle.These  cupcakes are light and moist with no trace of artificial, sickly sweet aftertaste.",
    },




    {
      img: cake3,
      review:
        "If you’re looking for amazing cake, you’ve got to order from here!. The strawberry with brownie  we got was AWESOME! It was super tasty, the flavor was great, and the frosting was excellent.",
    },
    {
      img: cake6,
      review:
        "Excellent cakes! Best prices! GREAT taste! I highly recommend for best flavors, decorations, and customer service. She goes above and beyond for all your needs.",
    },

    {
      img: cake8,
      review:
        " Chocoland is immensely talented in her decorating abilities. But it doesn’t stop there; her cakes are delicious! The flavor is incredible, and she always adds a unique touch to finish off her desserts for you.",
    },
    {
      img: cake7,
      review:
        "Wow, what a dream come true! I ordered the 'Dream Cake' for my special day and it exceeded all my expectations. The cake was moist and fluffy, with the perfect balance of sweetness and flavor.",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Testimonials & Feedback</span>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
