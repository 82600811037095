import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import "./App.css";
import Testimonial from "./components/Testimonials/Testimonial";
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <div
      className="App"
    >
      <Navbar />
      <Intro />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default App;
