import React, { useContext } from "react";
import "./Intro.css";
import cake from "../../img/cake.png";
import cupcake from "../../img/cupcake.png";
import donut from "../../img/donut.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import Whatsapp from "@iconscout/react-unicons/icons/uil-whatsapp";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };


  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
   
      {/* right image side */}
      <div className="i-right">

        <img src={cake} class="cake" />
        {/* animation */}
        <motion.img
          initial={{ top: "0%", left: "-16%", width: "20%" }}
          whileInView={{ left: "21%", width: "20%" }}
          transition={transition}
          src={cupcake}
          alt=""
        />

        <motion.img
          initial={{ top: "12%", left: "120%", width: "17%" }}
          whileInView={{ left: "72%", width: "17%" }}
          transition={transition}
          src={donut}
          class="donut"
          alt=""
        />
      </div>
      <div className="i-left">
        <div className="i-name">
          <span>Chocoland</span>
          <span>
            Indulge in the sweetest treats with our custom cake services!
            <ul>
          <li>Custom Cakes: Design your dream cake with our expert baker</li>
          <li>Wedding Cakes: Make your special day even sweeter with our stunning wedding cakes</li>
          <li>Birthday Cakes: Celebrate another year with a cake that's as unique as you</li>
          <li> Pre sliced Cakes,Cupcakes,Donuts,Puddings, cake pops, cake sicles, tarts</li>
          <li> Flavours:Butterscotch,Spanish delight,Kitkat,Red velvet cream cheese,Red bee,Rainbow,Choclate truffle,Kulfi,Tender cocunut,Mango truffle,Black current,Strawberry, Ferrarorocher, Belgium chocolate, Cheese,Almond,Redcho</li>
          </ul>
          </span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
        <a href="https://web.whatsapp.com/send?phone=+9745539272">
            <Whatsapp color="#7B3F00" size={"3rem"} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61560665318752&mibextid=ZbWKwL">
            <Facebook color="#7B3F00" size={"3rem"} />
          </a>
          <a href="https://www.instagram.com/___chocoland?igsh=OHBqangycGVrbm0y">
            <Insta color="#7B3F00" size={"3rem"} />
          </a>
        
        </Link>
      </div>
    </div>
  );
};

export default Intro;
